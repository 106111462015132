"use client";

import { useSession } from "@/lib/auth-client";
import { useTranslation } from "@/lib/i18n";
import { fallbackLng } from "@/lib/i18n/settings";
import { cn } from "@/lib/utils";
import { ArrowRight } from "lucide-react";
import React from "react";
import { buttonVariants } from "../ui/button";

const LoginButtonGroup: React.FC<{ lng?: string }> = ({
  lng = fallbackLng,
}) => {
  const { data: session } = useSession();
  const { t } = useTranslation({
    lng: lng,
    ns: "website",
  });

  const loginHref =
    lng === fallbackLng
      ? "/login?callbackUrl=/home"
      : `/${lng}/login?callbackUrl=/home`;

  if (session) {
    return (
      <div>
        <a
          href="/home"
          className={cn(
            buttonVariants({ variant: "outline", size: "default" }),
            "rounded-xl border-2 border-primary text-base text-primary hover:bg-primary hover:text-white",
          )}
        >
          {t("Create my CV")}
          <ArrowRight size={18} className="!size-[18px]" />
        </a>
      </div>
    );
  }

  return (
    <ul className="flex items-center gap-2">
      <li className="hidden lg:flex">
        <a href={loginHref} className={buttonVariants({ variant: "ghost" })}>
          {t("Login")}
        </a>
      </li>
      <li>
        <a href={loginHref} className={buttonVariants({ variant: "default" })}>
          {t("Sign Up")}
        </a>
      </li>
    </ul>
  );
};

export default LoginButtonGroup;
